<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GlobalServise from "../../core/services/globals-services.js";
import UserServices from "../../core/services/user-services.js";
import AdminStorage from "../../core/storage/admin-storage.js";
import ClientStorage from "../../core/storage/client-storage.js";
import ConfigStorage from "../../core/storage/config-storage.js";
import DashboardStorage from "../../core/storage/dashboard-storage.js";
import MarkStorage from "../../core/storage/mark-storage.js";
import NotifyStorage from "../../core/storage/notification-storage.js";
import OrderAdminStorage from "../../core/storage/order-admin-storage.js";
import OrderStorage from "../../core/storage/order-storage.js";
import ProductStorage from "../../core/storage/product-storage.js";
import SellerStorage from "../../core/storage/seller-storage.js";
import SettingStorage from "../../core/storage/setting-storage.js";
import UserStorage from "../../core/storage/user-storage.js";

export default {
  name: "Home",
  data: () => ({}),
  computed: {
    ...mapState(["user"]),
  },
  async created() {
    await this.getUserLogin();
    const user = this.user;
    const url = sessionStorage.getItem("url");
    if (user.typeUser.id === 2 || user.typeUser.id === 4) {
      const path = url ? url : "/mobile/mark";
      this.$router.push(path).catch(() => {});
    }

    if (user.typeUser.id === 1 || user.typeUser.id === 3) {
      const path = url ? url : "/admin/dashboard";
      this.$router.push(path).catch(() => {});
      const element = document.querySelector("body");
      element.classList.add("body-base");
    }

    this.webPush();
    this.logout();
  },
  methods: {
    ...mapActions(["getUserLogin"]),
    logout() {
      const { onLogout } = GlobalServise();
      const logoutSubscription = onLogout().subscribe((resp) => {
        if (resp) {
          this.unsubscribeWebPush(this.user);
          logoutSubscription.unsubscribe();

          if (resp && resp.code === 401) {
            this.$toast.warning("No autorizado para acceder a este recurso");
          }
        }
      });
    },
    modoDark() {
      this.$vuetify.theme.dark = false;
      document.body.classList.toggle("dark-theme-variables");
      const themeToggler = document.querySelector(".theme-toggler");
      themeToggler.querySelector("i:nth-child(1)").classList.toggle("active");
      themeToggler.querySelector("i:nth-child(2)").classList.toggle("active");
    },
    async unsubscribeWebPush(data) {
      const { updateUser } = UserServices();
      data.tokenNotification = "";
      updateUser(data.id, data).then((resp) => {
        const response = resp.data;
        if (response.code === 201) {
          this.clearData();
        } else {
          this.clearData();
        }
      });
    },
    clearData() {
      const { clearAdmin } = AdminStorage();
      const { clientClear } = ClientStorage();
      const { clearConfig, clearDarkMode } = ConfigStorage();
      const { dashboardClear } = DashboardStorage();
      const { markClear } = MarkStorage();
      const { notificationClear } = NotifyStorage();
      const { orderNewClear, orderCheckClear, orderCompleteClear } =
        OrderAdminStorage();
      const { orderClear } = OrderStorage();
      const { productClear } = ProductStorage();
      const { sellerClear } = SellerStorage();
      const { settingClear } = SettingStorage();
      const { clearUser } = UserStorage();

      clearAdmin();
      clientClear();
      clearConfig();
      dashboardClear();
      clearDarkMode();
      markClear();
      notificationClear();
      orderNewClear();
      orderCheckClear();
      orderCompleteClear();
      orderClear();
      productClear();
      sellerClear();
      settingClear();
      clearUser();
      // this.modoDark();
      sessionStorage.clear();

      this.$router.push("/login").catch(() => {});
    },
    webPush() {
      if (this.isIOS()) return;

      const data = this.user;
      if (Notification.permission === "granted") {
        // Si es correcto, lanzamos una notificación
        // new Notification("Notificaciones activadas");
        this.registeWebPush(data);
      }

      // Si no, pedimos permiso para la notificación
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          // Si el usuario nos lo concede, creamos la notificación
          if (permission === "granted") {
            // new Notification("Notificaciones activadas");
            this.registeWebPush(data);
          }
        });
      }
    },
    async registeWebPush(data) {
      const { updateUser } = UserServices();
      await navigator.serviceWorker.register("../service-worker.js", {
        scope: "/",
      });

      await navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey:
              "BAIJXhvSfbqDQUtEVCNrS8jci6k0pb1t_E-axo101_PTdxuIU905VZZRoAB_3IuV-XnoEzIcQeEz-BjZ6Yxfhw0",
          })
          .then((subscription) => {
            data.tokenNotification = JSON.stringify(subscription);
            updateUser(data.id, data).then();
          })
          .catch((error) => {
            if (Notification.permission === "denied") {
              console.log("Permission for Notifications was denied");
            } else {
              console.log("Unable to subscribe to push.", error);
            }
          });
      });
    },
    isIOS() {
      const browserInfo = navigator.userAgent.toLowerCase();

      if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
        return true;
      }
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
